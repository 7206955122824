<template>
	<div>
		<el-form class="addressForm" :model="AddressForm" :rules="rulesAddress" ref="ruleAddressForm" label-width="100px">
			<el-form-item label="姓名" prop="real_name"><el-input v-model="AddressForm.real_name"></el-input></el-form-item>
			<el-form-item label="手机号" prop="phone"><el-input v-model="AddressForm.phone"></el-input></el-form-item>

			<el-form-item label="所在地区" prop="model2">
				<el-cascader ref="cascader" v-model="AddressForm.model2" :options="district" :props="optionProps" @change="handleChange"></el-cascader>
			</el-form-item>

			<el-form-item label="详细地址" prop="detail"><el-input v-model="AddressForm.detail"></el-input></el-form-item>
			<el-form-item class="specialCase" label="身份证号码" prop="idcard"><el-input v-model="AddressForm.idcard"></el-input></el-form-item>
			<el-form-item label="上传身份证照片面" prop="idcard_front">
				<el-upload class="avatar-uploader" :action="action" :headers="headers" :show-file-list="false" :on-success="idcardFrontSuccess">
					<img v-if="AddressForm.idcard_front" :src="AddressForm.idcard_front" class="avatar" />
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</el-form-item>
			<el-form-item label="请上传身份证国徽面" prop="idcard_back">
				<el-upload class="avatar-uploader" :action="action" :headers="headers" :show-file-list="false" :on-success="idcardBackSuccess">
					<img v-if="AddressForm.idcard_back" :src="AddressForm.idcard_back" class="avatar" />
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</el-form-item>
			<el-form-item class="specialCase"><el-checkbox v-model="AddressForm.is_default">是否设为默认地址</el-checkbox></el-form-item>
			<el-form-item>
				<el-button type="primary" @click="onSubmit">保存</el-button>
				<el-button @click="cancleSubmit">取消</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
import { VUE_APP_API_URL } from '@utils';
import { postAddress, getAddress } from '@api/user';
export default {
	name: 'NewAddress',
	props: {
		district: {
			type: Array,
			default: () => []
		},
		id: {
			type: Number,
			default: 0
		}
	},
	data() {
		// 验证手机的自定义规则
		var validatePhone = (rule, value, cb) => {
			// 手机的正则验证
			const regPhone = /^(0|86|17951)?(13[0-9]|15[0-9]|17[678]|18[0-9|14[57])[0-9]{8}$/;
			if (regPhone.test(value)) {
				// 合法手机号
				return cb();
			}
			cb(new Error('请输入正确的手机号码'));
		};
		// 验证身份证的自定义规则
		var validateIdcard = (rule, value, cb) => {
			// 手机的正则验证
			const regIdcard = /^[1-9]\d{5}(18|19|2([0-9]))\d{2}(0[0-9]|10|11|12)([0-2][1-9]|30|31)\d{3}[0-9Xx]$/;
			if (regIdcard.test(value)) {
				// 合法手机号
				return cb();
			}
			cb(new Error('请输入正确的身份证号'));
		};
		return {
			action: `${VUE_APP_API_URL}/upload/image`,
			headers: {
				'Authori-zation': 'Bearer ' + window.sessionStorage.getItem('token')
			},
			optionProps: {
				expandTrigger: 'hover',
				value: 'n',
				label: 'n',
				children: 'c'
			}, // 格式化工单信息
			AddressForm: {
				real_name: '',
				phone: '',
				detail: '',
				idcard: '',
				model2: [],
				idcard_front: '',
				idcard_back: '',
				is_default: true
			},
			address: {
				province: '',
				city: '',
				district: '',
				city_id: ''
			},
			rulesAddress: {
				real_name: [{ required: true, message: '请输入姓名', trigger: 'blur' }, { min: 2, max: 3, message: '长度在 3 到 5 个字符', trigger: 'blur' }],
				phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }, { validator: validatePhone, trigger: 'blur' }],
				model2: [{ required: true, message: '请选择地址', trigger: 'blur' }],
				detail: [{ required: true, message: '请输入具体地址', trigger: 'blur' }],
				idcard: [{ required: true, message: '请输入身份证号', trigger: 'blur' }, { validator: validateIdcard, trigger: 'blur' }],
				idcard_front: [{ required: true, message: '请上传身份证照片面', trigger: 'blur' }],
				idcard_back: [{ required: true, message: '请上传身份证国徽面', trigger: 'blur' }]
			}
		};
	},
	watch: {
		id() {
			this.getUserAddress();
		}
	},
	mounted: function() {
		this.getUserAddress();
	},
	methods: {
		// 修改时获取数据
		getUserAddress: function() {
			let that = this;
			if (!that.id) {
				that.resetForm();
				return false;
			}
			getAddress(that.id).then(res => {
				that.AddressForm = res.data;
				that.address.province = res.data.province;
				that.address.city = res.data.city;
				that.address.district = res.data.district;
				that.address.city_id = res.data.city_id;
				that.AddressForm.model2 = [res.data.province, res.data.city, res.data.district];
				// that.AddressForm.authorId = true;
			});
		},
		handleChange(val) {
			let that = this;
			let nodesObj = this.$refs['cascader'].getCheckedNodes();
			that.address.province = val[0];
			that.address.city = val[1];
			that.address.district = val[2];
			that.address.city_id = nodesObj[0].parent.data.v;
		},
		resetForm() {
			this.$refs.ruleAddressForm.resetFields();
		},
		cancleSubmit() {
			this.$emit('innerVisibleClose');
		},
		onSubmit() {
			let that = this,
				data = {
					id: that.id,
					real_name: that.AddressForm.real_name,
					idcard: that.AddressForm.idcard,
					idcard_front: that.AddressForm.idcard_front,
					idcard_back: that.AddressForm.idcard_back,
					phone: that.AddressForm.phone,
					address: that.address,
					detail: that.AddressForm.detail,
					is_default: that.AddressForm.is_default,
					post_code: that.AddressForm.post_code ? that.AddressForm.post_code : ''
				};
			that.$refs.ruleAddressForm.validate(valid => {
				if (!valid) return;
				postAddress(data)
					.then(() => {
						if (that.id)
							that.$message({
								message: '修改成功',
								type: 'success'
							});
						else
							that.$message({
								message: '添加成功',
								type: 'success'
							});
						// 关闭弹窗并且调用地址列表接口
						that.$emit('saveAddress', that.id);
					})
					.catch(err => {
						that.$message.error(err.msg || '添加失败');
					});
			});
		},
		idcardFrontSuccess(res) {
			let that = this;
			if (res.status == 200) {
				that.$dialog.success(res.msg);
				that.AddressForm.idcard_front = res.data.url;
			}
		},
		idcardBackSuccess(res) {
			let that = this;
			if (res.status == 200) {
				that.$dialog.success(res.msg);
				that.AddressForm.idcard_back = res.data.url;
			}
		}
	}
};
</script>
<style lang="less" scoped>
.addressForm {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.el-form-item {
		width: 48%;
		.el-cascader {
			width: 100%;
		}
	}
	.el-form-item.specialCase {
		margin-right: 40%;
	}
	.avatar-uploader {
		border: 1px dashed #d9d9d9;
		.el-upload {
			display: flex !important;
			justify-content: center;
			img {
				width: 100%;
			}
			i {
				font-size: 28px;
				color: #8c939d;
				width: 178px;
				height: 178px;
				line-height: 178px;
				text-align: center;
			}
		}
	}
}
</style>
