<template>
	<div class="address_box">
		<div class="title">收货地址</div>
		<div class="addAddress" @click="newAddressVisible">新建收货地址</div>
		<el-form ref="searchAddress" size="mini" :inline="true" :model="formAddress" class="demo-form-inline">
			<el-form-item label="收件人姓名"><el-input clearable v-model="formAddress.name" placeholder="请输入收件人姓名"></el-input></el-form-item>
			<el-form-item label="手机号"><el-input clearable v-model="formAddress.phone" placeholder="请输入手机号"></el-input></el-form-item>
			<el-form-item label="身份证号"><el-input clearable v-model="formAddress.idCard" placeholder="请输入身份证号"></el-input></el-form-item>
			<el-form-item>
				<el-button type="primary" @click="onSubmit">搜索</el-button>
				<el-button @click="resetForm">重置</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="addressList" border stripe style="width: 100%">
			<el-table-column align="center" prop="real_name" label="收件人姓名"></el-table-column>
			<el-table-column align="center" prop="phone" label="手机号"></el-table-column>
			<el-table-column label="所在地区">
				<template slot-scope="scope">
					{{ scope.row.province + scope.row.city + scope.row.district }}
				</template>
			</el-table-column>
			<el-table-column prop="detail" label="详细地址"></el-table-column>
			<el-table-column prop="idcard" label="身份证号码"></el-table-column>
			<!-- <el-table-column label="身份证是否上传"></el-table-column> -->
			<el-table-column align="center" label="是否设为默认">
				<template slot-scope="scope">
					{{ scope.row.is_default == 1 ? '默认' : '/' }}
				</template>
			</el-table-column>
			<el-table-column align="center" label="操作">
				<template slot-scope="scope">
					<span class="operate" @click="editAddress(scope.row.id)">编辑</span>
					<span class="operate del" @click="delAddress(scope.$index, scope.row.id)">删除</span>
				</template>
			</el-table-column>
		</el-table>
		<el-dialog width="30%" :title="innerTitle" :visible.sync="innerVisible" append-to-body @close="innerVisibleClose">
			<New-address :id="id" :district="district" @innerVisibleClose="innerVisibleClose" @saveAddress="saveAddress"></New-address>
		</el-dialog>
	</div>
</template>

<script>
import NewAddress from '@components/NewAddress';
import { getAddressList, getCity } from '@api/user';
export default {
	name: 'AddressList',
	components: {
		NewAddress
	},
	data() {
		return {
			formAddress: {
				name: '',
				phone: '',
				idCard: ''
			},
			addressList: [],
			innerVisible: false,
			id: 0,
			innerTitle: '新建收货地址',
			district: []
		};
	},
	mounted: function() {
		let that = this;
		that.getAddressList();
		that.getCityList(); // 获取城市列表
	},
	methods: {
		innerVisibleClose() {
			let that = this;
			that.innerVisible = false;
		},
		saveAddress() {
			let that = this;
			that.innerVisible = false;
			that.getAddressList();
		},
		getCityList: function() {
			let that = this;
			getCity().then(res => {
				that.district = res.data;
			});
		},
		/**
		 * 编辑地址
		 */
		editAddress: function(index) {
			let that = this;
			that.innerTitle = '编辑收货地址';
			that.id = index;
			that.innerVisible = true;
		},
		/**
		 * 获取地址列表
		 *
		 */
		getAddressList: function() {
			let that = this;
			getAddressList().then(res => {
				that.$set(that, 'addressList', res.data);
			});
		},
		onSubmit() {
			this.$refs.searchAddress.validate(valid => {
				if (!valid) return;
				// 提交之后的操作
			});
		},
		resetForm() {
			this.$refs.searchAddress.resetFields();
		},
		// 新建地址显示
		newAddressVisible() {
			let that = this;
			that.innerTitle = '新建收货地址';
			that.id = 0;
			that.innerVisible = true;
		},
	}
};
</script>

<style lang="less" scoped>
.address_box {
	font-size: 14px;
	.title {
		font-weight: 700;
	}
	.addAddress {
		text-align: center;
		margin: 32px 0;
		cursor: pointer;
		background-color: #f60;
		width: 180px;
		height: 36px;
		line-height: 36px;
		border-radius: 4px;
		color: #fff;
	}
	.addAddress:hover {
		background-color: #ff8533;
	}
	.operate {
		cursor: pointer;
		margin: 0 10px;
	}
	.del {
		color: #f60;
	}
}
</style>
